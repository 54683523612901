import {queryList} from '@flashpointbv/solar-ui-catalog/dist/shared/helpers/index';

const resolveApiUrl = (categoryId: null|number = null): string => {
  if (state['searchedQuery'] !== '') {
    return `catalog/search/product/list`;
  }

  if (null === categoryId) {
    return `catalog/anonymous-category/product/list`;
  }

  return `catalog/category/${categoryId}/product/list`;
};

const resolveActiveFilters = (filters: Array<object> = []): Array<object> => {
  return filters.filter(item => {
    return item['type'] === 'attribute' ? item['options'].filter(o => o['active']).length > 0 : false;
  });
};

const compileFilterSegment = (activeFilters: Array<object> = []): object => {
  let filterSegments = {};

  activeFilters.forEach(filter => {
    (filter['options'] || []).forEach(option => {
      if (option['active']) {
        if ( ! filterSegments.hasOwnProperty(filter['facet_code'])) {
          filterSegments[filter['facet_code']] = [];
        }

        filterSegments[filter['facet_code']].push(`${option['label']}`);
      }
    });
  });

  return filterSegments;
};

const compileNewUrlQuery = (existingUrlQuery: object): object => {
  let newUrlQuery = {...existingUrlQuery};

  if (existingUrlQuery.hasOwnProperty('query') || state['searchedQuery']) {
    newUrlQuery['query'] = existingUrlQuery.hasOwnProperty('query')
      ? existingUrlQuery['query']
      : state['searchedQuery'];
  }

  const priceFilter = getters.priceFilters(state)[0] || null;

  if (null !== priceFilter) {
    if (existingUrlQuery.hasOwnProperty('minPrice') || priceFilter['MinimumValue'] > 0) {
      newUrlQuery['minPrice'] = existingUrlQuery.hasOwnProperty('minPrice')
        ? existingUrlQuery['minPrice']
        : (priceFilter['MinimumValue'] || 0);
    }

    if (existingUrlQuery.hasOwnProperty('maxPrice') || priceFilter['MaximumValue'] > 0) {
      newUrlQuery['maxPrice'] = existingUrlQuery.hasOwnProperty('maxPrice')
        ? existingUrlQuery['maxPrice']
        : (priceFilter['MaximumValue'] || 0);
    }
  }

  if (existingUrlQuery.hasOwnProperty('sort') || null !== getters.currentSort(state)) {
    if ( ! existingUrlQuery.hasOwnProperty('sort')) {
      const currentSort = Object.entries(getters.currentSort(state))[0];
      newUrlQuery['sort'] = `${currentSort[0]} ${currentSort[1]}`;
    } else {
      newUrlQuery['sort'] = existingUrlQuery['sort'];
    }
  }

  Object.entries(newUrlQuery).forEach(([key, value]) => {
    if (Array.isArray(value) && !value.length) {
      delete newUrlQuery[key];
    }
    if (null === value || '' === value) {
      delete newUrlQuery[key];
    }
  });

  return newUrlQuery;
};

const compileActiveFilterValues = (facets: Array<object>): Array<string> => {
  let urlFilters = [];

  facets.forEach(f => {
    if ( ! f['is_active'] || f['type'] === 'price') {
      return;
    }

    let optionStrings = [];

    f['options'].forEach(o => {
      if ( ! o['active']) {
        return;
      }

      optionStrings.push((f['has_duplicate_values'] ? `${f['url_key']}-` : '') + `${o['label']}`.replace(/\s/g, '-'));
    });

    if (optionStrings.length) {
      urlFilters.push(optionStrings.join('_'));
    }
  });

  return urlFilters;
};

const initialState = () => {
  return {
    searchedQuery: '',
    categoryData: null,
    catalogData: null,
    subCategoryData: null,
    apiResponsePending: false,
    pageQueries: {},
    productListBanner: null
  }
};

const state = initialState();

const mutations = {
  SET_CATEGORY_DATA(state, category) {
    state.categoryData = category;
  },
  SET_CATALOG_DATA(state, catalog) {
    state.catalogData = catalog;
  },
  SET_SUBCATEGORY_DATA(state, subCategories) {
    state.subCategoryData = subCategories;
  },
  SET_SEARCHED_QUERY(state, searchedQuery) {
    state.searchedQuery = searchedQuery;
  },
  SET_PRODUCT_LIST_BANNER(state, banner) {
    state.productListBanner = banner;
  },
  SET_API_RESPONSE_PENDING(state, status) {
    state.apiResponsePending = status;
  }
};

const actions = {
  updatePage({dispatch}, page) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {page: page})
    });
  },
  inCategorySearch({dispatch}, query) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {query: query})
    });
  },
  updatePageSize({dispatch}, pageSize) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {page_size: pageSize, page: 1})
    });
  },
  updatePriceFilter({dispatch}, payload) {
    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {minPrice: payload.minPrice, maxPrice: payload.maxPrice, page: 1})
    });
  },
  updatePageSort({dispatch}, sort) {
    let sortString = '';
    Object.entries(sort || {}).forEach(([key, value]) => {
      if (key !== '') {
        sortString = `${key} ${value}`
      }
    });

    dispatch('fetchCatalog', {
      pageQueries: Object.assign(queryList, {sort: sortString, page: 1})
    });
  },
  filterAction({dispatch, getters}, payload) {
    const pageQueries = {
      page: 1
    }

    const priceFilter = (getters.priceFilters[0] || null)
    if (priceFilter?.is_active) {
      if (priceFilter['minimum_value'] > 0) {
        Object.assign(pageQueries, {minPrice: priceFilter['minimum_value']});
      }

      if (priceFilter['maximum_value'] > 0) {
        Object.assign(pageQueries, {maxPrice: priceFilter['maximum_value']});
      }
    }

    const filters = getters['filterList'].map(filter => {
      if (filter['code'] !== payload['filterCode']) {
        return filter;
      }

      filter['options'].forEach(option => {
        if (option['label'] !== payload['optionLabel']) {
          return;
        }

        switch (payload['actionType']) {
          case 'toggle':
            option['active'] = !option['active'];
            break;
          case 'activate':
            option['active'] = true;
            break;
          case 'deactivate':
            option['active'] = false;
            break;
        }
      });

      filter['is_active'] = filter['options'].filter(o => o['active']).length > 0;

      return filter;
    });

    dispatch('fetchCatalog', {filters, pageQueries});
  },
  toggleFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'toggle',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  activateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'activate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  deactivateFilter({dispatch}, payload) {
    dispatch('filterAction', {
      actionType: 'deactivate',
      filterCode: payload.filter['code'],
      optionLabel: payload.option['label'],
    });
  },
  singleValueFilter({dispatch, getters}, payload) {
    dispatch('fetchCatalog', {
      filters: getters.filterList.map(filter => {
        if (filter['code'] === payload.filter['code']) {
          filter.options.forEach(o => {
            o['active'] = o['label'] === payload.option['label']
          });
        }
        return filter;
      })
    });
  },
  async fetchCatalog({state, commit}, payload) {
    commit('SET_API_RESPONSE_PENDING', true);

    let categoryId = null;

    if (payload && payload['categoryId']) {
      categoryId = payload['categoryId'];
    } else if (state.categoryData && state.categoryData['category_id']) {
      categoryId = state.categoryData['category_id'];
    }

    let filters = [];

    if (payload && payload['filters']) {
      filters = payload['filters'];
    } else if (state.catalogData && state.catalogData['filters']) {
      filters = state.catalogData['filters'];
    }

    const activeFilters = resolveActiveFilters(filters);
    const requestQuery = compileNewUrlQuery({...state.pageQueries, ...(payload['pageQueries'] || {})});

    try {
      const {data} = await this.$solarClient.get(resolveApiUrl(categoryId), {
        params: {...requestQuery, ...compileFilterSegment(activeFilters)}
      });

      const categoryData = data['category'] || null;
      const catalogData = data['catalog'];

      commit('SET_CATEGORY_DATA', categoryData);
      commit('SET_CATALOG_DATA', catalogData);
      commit('SET_SUBCATEGORY_DATA', data['sub_categories'] || []);
      commit('SET_PRODUCT_LIST_BANNER', data['banner'] || null);

      const activeFilterValues = compileActiveFilterValues(catalogData['filters'] || []);

      let urlSegments = window.location.pathname.split('/').filter(segment => segment);
      const urlHasFilterSegment = urlSegments.filter(segment => segment[0] === '_').length > 0;
      if (urlHasFilterSegment && !activeFilterValues.length) {
        urlSegments = urlSegments.filter(segment => segment[0] !== '_');
      }

      if (activeFilterValues.length) {
        const encodedActiveFilterValues = activeFilterValues.map(v => encodeURIComponent(v));

        if (urlHasFilterSegment) {
          urlSegments = urlSegments.map(segment => {
            return segment[0] === '_' && urlSegments.length ? `_${encodedActiveFilterValues.join('_')}` : segment;
          });
        } else {
          urlSegments.push(`_${encodedActiveFilterValues.join('_')}`);
        }
      }

      let queryString = [];
      Object.entries(requestQuery).forEach(([key, value]) => {
        queryString.push(`${key}=${encodeURIComponent(value)}`);
      });

      window.history.pushState({
          categoryData: categoryData,
          catalogData: catalogData
        },
        window.document.querySelector('title')['value'],
        window.location.origin + `/${urlSegments.join('/')}` + (queryString.length ? `?${queryString.join('&')}` : '')
      );
    } catch (e) {
      console.error('Something went wrong fetch catalog data...', e);
    }

    commit('SET_API_RESPONSE_PENDING', false);
  }
};

const getters = {
  isLoading: state => {
    return state.apiResponsePending;
  },
  currentPage: state => {
    return state.catalogData ? state.catalogData['current_page'] : 1;
  },
  currentSort: state => {
    return state.catalogData ? state.catalogData['sorting'] : null;
  },
  sortingOptions: state => {
    return state.catalogData ? state.catalogData['sorting_options'] : [];
  },
  totalPages: state => {
    return state.catalogData ? state.catalogData['last_page'] : 1;
  },
  pageSize: state => {
    return state.catalogData ? state.catalogData['page_size'] : 24;
  },
  productList: state => {
    return state.catalogData ? (state.catalogData.hasOwnProperty('items') ? state.catalogData['items'] : []) : [];
  },
  filterList: state => {
    return state.catalogData ? (state.catalogData.hasOwnProperty('filters') ? state.catalogData['filters'] : []) : [];
  },
  priceFilters: state => {
    if (state.catalogData && state.catalogData.hasOwnProperty('filters')) {
      return state.catalogData.filters.filter(o => {
        return o.type === 'price'
      });
    }
  },
  activeFilters: (state, getters) => {
    return getters.filterList.filter(filter => {
      return filter['type'] !== 'attribute' ? false : filter['is_active'];
    });
  },
  activeCrawlableFilters: (state, getters) => {
    return getters.activeFilters.filter(filter => filter['is_crawlable']);
  },
  totalItemCount: state => {
    return state.catalogData ? (state.catalogData.hasOwnProperty('total_items') ? state.catalogData['total_items'] : 0) : 0;
  },
  getProductListBanner: state => state.productListBanner
};

const CatalogCategory = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

export default CatalogCategory;

import Vue from 'vue';

const initialState = (state: object): object => ({...state})

const state = initialState({
    productsToCompare: []
});

const getters = {
    getProductsToCompare: (state: object) => state['productsToCompare'],
    getSkuList: (state: object) => state['productsToCompare'].map((product: object) => product['sku']),
    getTotalItems: (state: object) => state['productsToCompare'].length,
};

const mutations = {
    SET_PRODUCTS_TO_COMPARE(state: object, products: Array<object>) {
        Vue.set(state, 'productsToCompare', products);
    },
    ADD_PRODUCT_TO_COMPARE(state: object, product: object) {
        const alreadyExists = state['productsToCompare'].find((item: object) => item['sku'] === product['sku']);

        if ( ! alreadyExists) {
            Vue.set(state['productsToCompare'], state['productsToCompare'].length, product);
        }
    },
    REMOVE_PRODUCT_FROM_COMPARE(state: object, sku: string) {
        const products = state['productsToCompare'];
        const index = products.findIndex((product: object) => product['sku'] === sku);

        if (index !== -1) {
            Vue.delete(products, index);
        }
    },
};

const actions = {
    init({commit}, products: Array<object>): void {
        commit('SET_PRODUCTS_TO_COMPARE', products);
    },
    removeProductFromCompare({commit, dispatch}, sku: string): void {
        commit('REMOVE_PRODUCT_FROM_COMPARE', sku);
        dispatch('updateCookie', {
            sku: sku,
        });
    },
    addProductToCompare({commit, dispatch}, product: object): void {
        commit('ADD_PRODUCT_TO_COMPARE', product);
        dispatch('updateCookie', {
            sku: product['sku'],
        });
    },
    updateCookie({}, sku: string): void {
        this.$solarStatefulClient.post('/api/catalog/compare/toggle-sku', sku);
    },
};

const CatalogProductCompare = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};

export default CatalogProductCompare;

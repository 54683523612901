import Vue from 'vue';

const initialState = () => {
    return {
        bundleOptionsInfo: {},
        bundleProductIds: {},
        bundleProducts: {},
        selectedOptions: {},
        bundlePrice: {},
        bundleDiscountPercentage: {},
        bundleSizeCharts: {},
        bundleDiscountAmount: {},
    }
};

const state = initialState();

const mutations = {
    SET_BUNDLE_DATA(state: object, data: object): void {
        Vue.set(state['bundleOptionsInfo'], data['bundleId'], data['bundleOptionsInfo']);
        Vue.set(state['bundleProductIds'], data['bundleId'], data['bundleProductIds']);
        Vue.set(state['bundleProducts'], data['bundleId'], data['bundleProducts']);
        Vue.set(state['bundleDiscountAmount'], data['bundleId'], data['bundleDiscountAmount'] || 1);
        Vue.set(state['bundleDiscountPercentage'], data['bundleId'], data['bundleDiscountPercentage'] || 1.0);
    },
    REMOVE_BUNDLE_DATA(state: object, bundleId: number): void {
        Vue.delete(state['bundleOptionsInfo'], bundleId);
        Vue.delete(state['bundleProductIds'], bundleId);
        Vue.delete(state['bundleProducts'], bundleId);
        Vue.delete(state['bundleDiscountAmount'], bundleId);
        Vue.delete(state['bundleDiscountPercentage'], bundleId);
        Vue.delete(state['selectedOptions'], bundleId);
        Vue.delete(state['bundlePrice'], bundleId);
        Vue.delete(state['bundleSizeCharts'], bundleId);
    },
    SET_BUNDLE_SIZE_CHARTS(state: object, data: object): void {
        Vue.set(state['bundleSizeCharts'], data['bundleId'], data['bundleSizeCharts']);
    },
    SET_SELECTED_OPTIONS(state: object, payload: object): void {
        if (payload['type'] !== 'checkbox') {
            Vue.set(state['selectedOptions'], payload['bundleId'], (state['selectedOptions'][payload['bundleId']] || []).filter((o: object) => o['option_id'] !== payload['option_id']));
        }

        if (payload['type'] === 'checkbox' && ! payload['is_checked']) {
            const index = state['selectedOptions'][payload['bundleId']].findIndex((o: object) => o['selection_id'] === payload['selection_id']);
            Vue.delete(state['selectedOptions'][payload['bundleId']], index);
            return;
        }

        // Create new array if not exists
        if ( ! state['selectedOptions'][payload['bundleId']]) {
            Vue.set(state['selectedOptions'], payload['bundleId'], []);
        }

        state['selectedOptions'][payload['bundleId']].push(payload);
    },
    DELETE_SELECTED_OPTION(state: object, payload: object): void {
        if (state['selectedOptions'][payload['bundleId']]) {
            Vue.delete(state['selectedOptions'][payload['bundleId']], payload['index']);
        }
    },
    SET_CONFIGURABLE_OPTIONS(state: object, payload: object): void {
        Vue.set(state['selectedOptions'][payload['bundleId']][payload['index']], 'selected_options', payload['option']);
        Vue.set(state['selectedOptions'][payload['bundleId']][payload['index']], 'selected_option_prices', payload['prices']);
    },
    SET_BUNDLE_PRICE(state: object, payload: object): void {
        Vue.set(state['bundlePrice'], payload['bundleId'], payload['prices']);
    }
};

const actions = {
    setBundleData({commit}, data: object): void {
        commit('SET_BUNDLE_DATA', data);
    },
    removeBundleData({commit}, bundleId: number): void {
        commit('REMOVE_BUNDLE_DATA', bundleId);
    },
    setBundleSizeCharts({commit}, data: object): void {
        commit('SET_BUNDLE_SIZE_CHARTS', data);
    },
    updateSelectedOption({state, commit, dispatch}, payload: object): void {
        if (payload.hasOwnProperty('type')) {
            switch (payload['type']) {
                case 'radio':
                    const sameOptionGroupSelectedIndex = (state.selectedOptions[payload['bundleId']] || []).findIndex((s: object) => s['option_id'] === payload['option_id']);
                    if (sameOptionGroupSelectedIndex >= 0) {
                        commit('DELETE_SELECTED_OPTION', {
                            index: sameOptionGroupSelectedIndex,
                        });
                    }
                    commit('SET_SELECTED_OPTIONS', payload);
                    break;
                case 'select':
                    if ( ! payload['active']) {
                        commit('DELETE_SELECTED_OPTION', {
                            bundleId: payload['bundleId'],
                            index: state.selectedOptions[payload['bundleId']].findIndex((s: object) => s['option_id'] === payload['option_id']),
                        });
                        return;
                    }
                    commit('SET_SELECTED_OPTIONS', payload);
                    break;
                case 'checkbox':
                    commit('SET_SELECTED_OPTIONS', payload);

                    if ( ! payload['is_checked']) {
                        // Make sure to also remove the custom options & team wear products
                        dispatch('CatalogProductPersonalisation/removeProductFromPersonalisationGroup', {
                            groupId: payload['bundleId'],
                            productId: payload['product_id'],
                        }, {root: true});

                        dispatch('CatalogProductTeamWearView/removeSelectPlayerProducts', {
                            bundleId: payload['bundleId'],
                            productId: payload['product_id'],
                        }, {root: true});
                    }
                    break;
                default:
                    commit('SET_SELECTED_OPTIONS', payload);
                    break;
            }
        }
    },
    setConfigurableOptions({state, commit}, payload: object): void {
        const selectedOption = (state.selectedOptions[payload['bundleId']] || []).find((o: object) => o['product_id'] === payload['productId']) || null;

        if ( ! selectedOption) {
            return;
        }

        commit('SET_CONFIGURABLE_OPTIONS', {
            bundleId: payload['bundleId'],
            index: state.selectedOptions[payload['bundleId']].indexOf(selectedOption),
            option: payload['attributes'],
            prices: payload['prices'],
        });
    }
};

const getters = {
    getBundleOptionsInfo: (state: object): object => state['bundleOptionsInfo'],
    getBundleSizeCharts: (state: object): object => state['bundleSizeCharts'],
    getBundleProducts: (state: object): object => state['bundleProducts'],
    selectedOptions: (state: object): object => state['selectedOptions'],
    requiredOptions: (state: object, getter: object): object => {
        return Object.fromEntries(Object.entries(getter['getBundleOptionsInfo']).map(([k, v]) => {
            return [k, (v as Array<object>).filter((o: object) => o['required'] === 1)];
        }))
    },
    requiredAttributeOptions: (state: object): object => {
        return Object.fromEntries(Object.entries(state['selectedOptions']).map(([k, v]) => {
            return [k, !! (v as Array<object>).filter((o: object) => o.hasOwnProperty('configurable_options') && o['configurable_options'].length > 0 && o['selected_options'] === null).length]
        }));
    },
    totalPrices: (state: object, getters: object): object => {
        //TODO: Refactor this
        return <Object>Object.entries(state['bundlePrice']).map(([k, v]) => {
            const prices = {
                priceInclTax: 0,
                priceExclTax: 0,
                totalPriceInclTax: 0,
                totalPriceExclTax: 0,
            };

            (getters['getBundleOptionsInfo'][k] || []).forEach((o: object) => {
                prices.priceInclTax += (o['selections'] || []).reduce((a: number, b: object) => a + (b['prices']?.['price_incl_tax'] * b['selection_qty']), 0);
                prices.priceExclTax += (o['selections'] || []).reduce((a: number, b: object) => a + (b['prices']?.['price_excl_tax'] * b['selection_qty']), 0);
            });

            (getters['selectedOptions'][k] || []).forEach((o: object) => {
                const totalPriceInclTax = ((o.hasOwnProperty('selected_option_prices') ? o['selected_option_prices'].final_price_incl_tax : o['prices'].final_price_incl_tax) + o['selection_price_value']) * o['selection_qty'];
                const totalPriceExclTax = ((o.hasOwnProperty('selected_option_prices') ? o['selected_option_prices'].final_price_excl_tax : o['prices'].final_price_excl_tax) + o['selection_price_value']) * o['selection_qty'];

                prices.totalPriceInclTax += state['bundleDiscountPercentage'][k] ? Math.round(totalPriceInclTax * state['bundleDiscountPercentage'][k] * 100) / 100 : totalPriceInclTax;
                prices.totalPriceExclTax += state['bundleDiscountPercentage'][k] ? Math.round(totalPriceExclTax * state['bundleDiscountPercentage'][k] * 100) / 100 : totalPriceExclTax;
            });

            return v ? v : prices;
        }).reduce((a, b) => {
            a['priceInclTax'] += b['priceInclTax'];
            a['priceExclTax'] += b['priceExclTax'];
            a['totalPriceInclTax'] += b['totalPriceInclTax'];
            a['totalPriceExclTax'] += b['totalPriceExclTax'];
            return a;
        });
    },
    totalBundlePrices: (state: object, getters: object): object => {
        return <Object>Object.fromEntries(Object.entries(state['bundlePrice']).map(([k, v]) => {
            const prices = {
                priceInclTax: 0,
                priceExclTax: 0,
                totalPriceInclTax: 0,
                totalPriceExclTax: 0,
            };

            (getters['getBundleOptionsInfo'][k] || []).forEach((o: object) => {
                prices.priceInclTax += (o['selections'] || []).reduce((a: number, b: object) => a + (b['prices']?.['price_incl_tax'] * b['selection_qty']), 0);
                prices.priceExclTax += (o['selections'] || []).reduce((a: number, b: object) => a + (b['prices']?.['price_excl_tax'] * b['selection_qty']), 0);
            });

            (getters['selectedOptions'][k] || []).forEach((o: object) => {
                const totalPriceInclTax = ((o.hasOwnProperty('selected_option_prices') ? o['selected_option_prices'].final_price_incl_tax : o['prices'].final_price_incl_tax) + o['selection_price_value']) * o['selection_qty'];
                const totalPriceExclTax = ((o.hasOwnProperty('selected_option_prices') ? o['selected_option_prices'].final_price_excl_tax : o['prices'].final_price_excl_tax) + o['selection_price_value']) * o['selection_qty'];

                prices.totalPriceInclTax += state['bundleDiscountPercentage'][k] ? Math.round(totalPriceInclTax * state['bundleDiscountPercentage'][k] * 100) / 100 : totalPriceInclTax;
                prices.totalPriceExclTax += state['bundleDiscountPercentage'][k] ? Math.round(totalPriceExclTax * state['bundleDiscountPercentage'][k] * 100) / 100 : totalPriceExclTax;
            });

            return [k, v ? v : prices];
        }));
    },
    totalSelectedOptions: (state: object) => {
        return Object.values(state['selectedOptions']).reduce((a: number, b: Array<object>) => a + b.length, 0);
    },
    isSaleablePerBundle: (state: object, getters: object): object => {
        return Object.fromEntries(Object.entries(getters['requiredOptions'] || []).map(([k, v]) => {
            const selectedRequiredOptions: Array<boolean> = [];
            (v as Array<object>).forEach((o: object) => {
                selectedRequiredOptions.push((getters['selectedOptions'][k] || []).filter((r: object) => r['option_id'] === o['option_id'] && r['qty'] > 0 && r['configurable_options']).length > 0);
            });
            return [k, selectedRequiredOptions.every(Boolean) && ! getters['requiredAttributeOptions'][k]];
        }));
    },
    isSaleable: (state: object, getters: object): boolean => {
        if (getters['totalSelectedOptions'] === 0) {
            return false;
        }

        return Object.values(getters['isSaleablePerBundle']).every(Boolean);
    },
    getAddToQuotePayload: (state: object, getters: object): object => {
        return Object.fromEntries(Object.entries(getters['selectedOptions']).map(([k, v]) => {
            return [k, (v as Array<object>).map((o: object) => {
                if (o['selected_options']) {
                    const selectedOptions = o['selected_options'].map((s: object) => {
                        return {
                            super_attribute_id: s['id'],
                            super_attribute_option_id: s['value']
                        };
                    });

                    return {
                        'option_id': o['option_id'],
                        'option_qty': o['qty'],
                        'option_selections': [o['selection_id']],
                        'extension_attributes': {
                            'selection_configurable_options': selectedOptions
                        }
                    };
                }

                return {
                    'option_id': o['option_id'],
                    'option_qty': o['qty'],
                    'option_selections': [o['selection_id']]
                };
            })];
        }));
    }
};

const CatalogBundleProduct = {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};

export default CatalogBundleProduct;

export default {
    "Categories": "Categorie\u00ebn",
    "Search results for &ldquo;{query}&rdquo;": "Zoekresultaten voor &ldquo;{query}&rdquo;",
    "What do our customers say": "Wat zeggen onze klanten",
    "{shop} scores a {average} based on {total} reviews": "{shop} krijgt een {average} op basis van {total} beoordelingen",
    "By": "By",
    "Subscribe": "Schrijf je in",
    "Created by": "Realisatie webshop",
    "{count} ratings": "{0} 0 beoordelingen|{1} {count} beoordeling|[2,*] {count} beoordelingen",
    "Alternate Products": "Alternatieven",
    "Product Description": "Omschrijving",
    "Specifications": "Kenmerken",
    "Related Products": "Handig voor erbij",
    "Size Chart": "Maattabel",
    "Size Advice": "Maatadvies",
    "Reviews": "Reviews",
    "All about this product": "Alles over dit product",
    "All about the {name}": "Alles over de {name}",
    "Read more": "Lees meer",
    "Rating of <span>&rdquo;{name}&ldquo;</span>": "Algemene beoordeling van <span>&rdquo;{name}&ldquo;</span>",
    "<span>{rating}</span> based on <span>{count}</span> reviews": "<span>{rating}</span> gebasseerd op <span>{count}</span> reviews",
    "Written by {name} on {date}": "Door {name} op {date}",
    "Thank you for your order!": "Bedankt voor je order!",
    "Your order has been placed successfully.": "Jouw order is succesvol geplaatst.",
    "Your ordernumber is: #{order_id}.": "Je order nummer is: #{order_id}.",
    "You will receive an e-mail confirming your order with all the details within 15 minutes. Also check your spam folder if you have not received a confirmation email after 15 minutes. If you have any questions about the status of your order, it is best to contact customer service.": "Je ontvangt binnen 15 minuten een e-mail ter bevestiging van jouw bestelling met daarin alle details. Controleer je spam-map als je na 15 minuten nog geen bevestiging e-mail hebt ontvangen. Als je vragen hebt over de status van jouw bestelling, kun je het beste contact opnemen met de klantenservice.",
    "Billing address": "Factuur adres",
    "Shipping address": "Adresgegevens",
    "Pick up your order at:": "Je kunt jouw order ophalen op de volgende locatie:",
    "Recent Blog Posts": "Recente Blog Posts",
    "Previous": "Vorige",
    "Next": "Volgende",
    "Tags": "Tags",
    "Products by this post": "Producten in deze post",
    "Login": "Login",
    "Email address": "E-mailadres",
    "Password": "Wachtwoord",
    "Confirm password": "Bevestig Wachtwoord",
    "Forgot password?": "Wachtwoord vergeten?",
    "Benefits of an account": "Voordelen van een account",
    "Create account": "Account aanmaken",
    "You are now signed out": "Je bent nu uitgelogd",
    "You are now logged out and will return to our home page within 5 seconds.": "U bent nu uitgelogd en zult binnen 5 seconden terugkeren naar de homepagina.",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "Date of birth": "Geboortedatum",
    "DD": "DD",
    "MM": "MM",
    "YYYY": "YYYY",
    "Male": "Man",
    "Female": "Vrouw",
    "Subscribe to our newsletter": "Schrijf je in voor onze nieuwsbrief",
    "Welcome {name}": "Welkom {name}",
    "This personal page offers you an overview of the most recent activities. You can, among other things, view orders and update account information. Click on a link below to get started!": "Deze persoonlijke pagina biedt je een overzicht van de meest recente activiteiten. Je kunt onder andere bestellingen bekijken en account informatie bijwerken!",
    "Recent orders": "Recente orders",
    "You have not placed any orders yet": "Je hebt nog geen order bij ons geplaatst",
    "Order #": "Order #",
    "Date": "Datum",
    "Ship to": "Verzend naar",
    "Total": "Totaal",
    "View order": "Bekijk order",
    "Personal settings": "Persoonlijke instellingen",
    "Contact details": "Contactgegevens",
    "Edit": "Wijzig",
    "Change password": "Verander je wachtwoord",
    "Newsletter": "Nieuwsbrief",
    "You are subscribed to our newsletter": "Je bent ingeschreven voor onze nieuwsbrief",
    "subscribe": "Schrijf je in",
    "Addresses": "Adressen",
    "Default shipping address": "Standaard verzendadres",
    "No default shipping address available": "Er is geen standaard verzendadres beschikbaar",
    "Default billing address": "Standaard Factuuradres",
    "No default billing address available": "Er is geen standaard factuuradres beschikbaar",
    "Edit personal settings": "Wijzig persoonlijke instellingen",
    "Current password": "Huidige wachtwoord",
    "New password": "Nieuw wachtwoord",
    "Confirm new password": "Bevestig nieuw wachtwoord",
    "Save": "Opslaan",
    "Add new address": "Nieuw adres toevoegen",
    "Company": "Bedrijf",
    "Phone number": "Telefoonnummer",
    "Address": "Adres",
    "Postal code + house number": "Postcode + Huisnummer",
    "Postal code": "Postcode",
    "House no.": "Huisnummer",
    "Street": "Straat",
    "City": "Plaats",
    "Country": "Land",
    "Select a country": "Selecteer een land",
    "Save address": "Adres opslaan",
    "Edit address": "Adres wijzigen",
    "Extra Addresses": "Extra adres",
    "No addresses available": "Geen adres beschikbaar",
    "Remove": "Verwijder",
    "Account overview": "Account overzicht",
    "My orders": "Mijn orders",
    "My reviews": "Mijn reviews",
    "Newsletters": "Nieuwsbrief",
    "Wishlist": "Verlanglijstje",
    "Logout": "Uitloggen",
    "Do you receive our newsletter?": "Ontvang jij onze nieuwsbrief?",
    "Order {increment}": "Order {increment}",
    "Shipping method": "Verzendmethode",
    "Payment method": "Betaalmethode",
    "Ordered items": "Bestelde artikelen",
    "Item": "Item",
    "SKU": "SKU",
    "Price": "Prijs",
    "Quantity": "Aantal",
    "Subtotal": "Subtotaal",
    "Shipping": "Verzending",
    "Free": "Gratis",
    "Grand total": "Totaal",
    "Request password": "Wachtwoord aanvragen",
    "You have not reviewed any products yet": "Je hebt nog geen product reviews achter gelaten",
    "Previously viewed by you": "Eerder bekeken producten",
    "Apply": "Toepassen",
    "Cancel": "Annuleer",
    "Search for...": "Zoek naar...",
    "More results for {inputQuery}": "Meer resultaten voor {inputQuery}",
    "No results found for {inputQuery}": "Geen resultaten gevonden voor {inputQuery}",
    "Select an option": "Selecteer een optie",
    "More": "Meer",
    "Be inspired": "Laat je inspireren",
    "Active filters": "Geselecteerde filters",
    "More options": "Meer",
    "Less options": "Minder",
    "to": "tot",
    "Enter a correct value": "Voer een correcte waarde in",
    "Sizes": "Maten",
    "Filter": "Filter",
    "Show results": "Resultaten tonen",
    "Show {totalItems} results": "Geen resultaten | Toon 1 resultaat | Toon {totalItems} resultaten",
    "No products were found in this category.": "Er zijn geen producten gevonden in deze categorie.",
    "{count} results": "Geen resultaten | 1 resultaat | {count} resultaten",
    "Sort by": "Sorteer op",
    "Latest releases": "Nieuwste releases",
    "Name (A-Z)": "Naam (A-Z)",
    "Name (Z-A)": "Naam (Z-A)",
    "Price (Ascending)": "Prijs (laag-hoog)",
    "Price (Descending)": "Prijs (hoog-laag)",
    "Choose your {option}": "Kies je {option}",
    "Notify me when my size is back in stock": "Laat me weten wanneer mijn maat weer op voorraad is",
    "Thank you for your request, you will be notified by mail when the size is back in stock!": "Bedankt voor je aanvraag, je wordt per e-mail op de hoogte gebracht wanneer de maat weer op voorraad is!",
    "Out of stock notification": "Out of stock melding",
    "Choose your size": "Kies je maat",
    "Send": "Verstuur",
    "You don't seem to be logged in, please login to enable stock notifications.": "Je lijkt niet ingelogd te zijn, gelieve in te loggen om de voorraad meldingen te activeren.",
    "If you don't already have an account, you can register one {here}": "Als je nog geen account hebt, kun je er {here} een registreren",
    "here": "hier",
    "By {name} on {date}": "Door {name} op {date}",
    "Added to cart": "Toegevoegd aan winkelwagen",
    "To shopping cart": "Naar winkelwagen",
    "Nice to add": "Leuk om toe te voegen",
    "You may also like this": "Dit vind je misschien ook leuk",
    "Select one of the required options.": "Selecteer een van de vereiste opties.",
    "Choose your personalisation options": "Kies je bedrukking",
    "Personalize product": "Bedrukken",
    "Personalize product with name and/or number": "Laat dit product bedrukken met een naam en/of nummer!",
    "Choose your stringing personalisation options": "Bespannen",
    "Personalize product with stringing": "Mijn racket professioneel laten bespannen",
    "Personalize product with stringing and/or weight": "Kies jouw gewenste snaar en gewicht",
    "Snaar": "Snaar",
    "Gewicht": "Gewicht",
    "Choose {option}": "Kies je {option}",
    "Add to cart": "In winkelwagen",
    "Featured": "Aanbevolen",
    "Pay safe": "Veilig betalen",
    "Write a review": "Schrijf een review",
    "Your rating": "Jouw beoordeling",
    "name": "Naam",
    "Enter your name": "Voer je naam in",
    "description": "Omschrijving",
    "Short description of your review": "Korte omschrijving van je review",
    "review": "review",
    "Submit review": "Verstuur review",
    "Unable to save review": "Kan de review niet plaatsen",
    "Thanks for placing a review!": "Bedankt voor het achterlaten van een review!",
    "Shopping cart": "Winkelmandje",
    "To checkout": "Ik ga bestellen",
    "One or more products are out of stock.": "E\u00e9n of meerdere producten zijn niet meer op voorraad",
    "Continue shopping": "Verder winkelen",
    "You have no product(s) in your shopping cart.": "Je hebt geen producten in je winkelmandje",
    "Click {url} to continue shopping": "Klik {url} om door te gaan met winkelen",
    "Loading shopping cart items...": "Je winkelmandje wordt geladen",
    "Product out of stock": "Product is uitverkocht",
    "Ordered before 22:00, <span>delivered for free</span> the next day": "Voor 22:00, <span>de volgende dag gratis geleverd</span>",
    "Sold out! Will be expected at <span>{date}</span>.": "Tijdelijk uitverkocht! We verwachten dit product op <span>{date}.</span>",
    "Sold out!": "Dit artikel is helaas uitverkocht",
    "Will be expected at <span>{date}</span>.": "We verwachten dit product op <span>{date}</span>.",
    "Expected delivery: {date}": "Verwachte leverdatum {date} werkdagen",
    "Delivery costs": "Verzending",
    "Discount": "Je bespaart",
    "Payment": "Betaling",
    "Complete!": "Klaar!",
    "Log in": "Log in",
    "Name": "Naam",
    "Choose your country": "Kies je land",
    "House number": "Huisnummer",
    "Addition": "Toevoeging",
    "Add.": "Toevoegen",
    "Telephone": "Telefoonnummer",
    "Click here to automatically retrieve your address": "Klik hier om je adres automatisch op te vragen",
    "We have found the following address": "We hebben het volgende adres gevonden",
    "Invalid address. Are you sure it is correct?": "Ongeldig adres. Weet je zeker dat het juist is ingevuld?",
    "Click here to enter the address manually.": "Klik hier om het adres handmatig in te voeren.",
    "Select your address": "Selecteer jouw adres",
    "Login with your account": "Login met je account",
    "You have an account. Would you like to {action}?": "Je hebt al een account, wil je {action}?",
    "login": "lnloggen",
    "I want to add a comment": "Ik wil een opmerking bij mijn order plaatsen",
    "Write your comment": "Schrijf je opmerking",
    "Without extra fees": "Zonder extra kosten",
    "Credit card": "Credit card",
    "Choose your credit card": "Kies je credit card",
    "The most used payment method in The Netherlands": "Meest gebruikte betaalmethode in Nederland",
    "Bank": "Bank",
    "Choose your bank": "Kies je bank",
    "Do you already have an account with us? Log in {action} and we will get your data.": "Heb je al een account bij ons? Log dan in {action} zodat we je gegevens op kunnen halen.",
    "Billing address is different from the address above": "Factuuradres is verschillend van bovenstaand adres",
    "Sign me up for the newsletter": "Schrijf mij in voor de nieuwsbrief",
    "Create an account": "Account aanmaken",
    "Payment methods": "Betaalmethodes",
    "Something went wrong, the payment could not be completed. Please try again or choose a different payment method.": "De betaling kon niet worden voltooid. Probeer het opnieuw of kies een andere betaalmethode.",
    "Choose a payment method to complete your order": "Kies een betaalmethode om je bestelling af te ronden",
    "Shipping methods": "Verzendopties",
    "Choose a delivery method to proceed to the next step": "Kies een verzend optie om naar de volgende stap te gaan",
    "Overview of your order": "Overzicht van je order",
    "You can pick up your order at": "Je kunt je order ophalen op",
    "Your order will be delivered to": "Je order zal verstuurd worden naar",
    "Change delivery address": "Wijzig afleveradres",
    "Some items in your shopping cart are out of stock. {action}": "Somige producten uit je winkelwagen zijn helaas niet meer op voorraad. {action}",
    "View products.": "bekijk producten.",
    "Previous step": "Vorige stap",
    "To shipping": "Naar verzending",
    "Continue": "Doorgaan",
    "Complete order": "Bestellen en betalen",
    "I have a discount code": "Ik heb een kortingscode",
    "Your applied coupon code is {couponCodeInput}.": "Je ingevoerde kortingscode is {couponCodeInput}.",
    "Select a {carrier} pick-up point": "Selecteer een {carrier} PostNL-ophaalpunt",
    "Please wait, we are loading the stores ...": "Even geduld, we zijn PostNL-ophaalpunten aan het laden ...",
    "personal ID": "personal ID",
    "{distance} kilometer": "{distance} kilometer",
    "{distance} meter": "{distance} meter",
    "Opening hours": "Openingstijden",
    "Enter your postal code": "Voer je postcode in",
    "My wishlist": "Mijn verlanglijstje",
    "There are no products in your wishlist": "Er zitten geen producten in je verlanglijstje",
    "Request return": "Retour aanvragen",
    "Sign up": "Aanmelding",
    "Conditions": "Condities",
    "Instructions": "Instructies",
    "Thanks": "Bedankt",
    "Order number": "Bestelnummer",
    "Submit complaint": "Klacht toevoegen",
    "Order": "Order",
    "Order Date:": "orderdatum:",
    "Items ordered": "Bestelde producten",
    "Product": "Producten",
    "Qty": "Aantal",
    "Return": "Retour",
    "Do you have a comment for this return?": "Wil je een opmerking bij deze retour plaatsen?",
    "Submit return": "Retour toevoegen",
    "Accept terms and conditions on return submit note": "Accepteer de voorwaarden en condities van deze retour.",
    "Print return ticket": "Print retour ticket",
    "Finalize return": "Retour afronden",
    "Upload image": "Upload afbeelding",
    "No image chosen": "Geen afbeeldingen geslecteerd",
    "Complaint is a required field.": "Klacht is een verplicht veld.",
    "Something went wrong, please try again later": "Er ging iets mis, probeer het later nog eens",
    "ordered": "Besteld",
    "Can't be returned": "Kan niet worden geretourneerd",
    "Already returned": "Al geretourneerd",
    "Message": "Bericht",
    "Submit": "Verstuur",
    "Contact us": "Neem contact op met onze klantenservice",
    "Invalid re-captcha.": "Ongeldige re-captcha.",
    "Your message has been sent.": "Je bericht is verstuurd, we nemen zo snel mogelijk contact met je op.",
    "validation.required": "Veld '{attribute}' is verplicht",
    "validation.email": "Ongeldig e-mailadres voor veld '{attribute}'",
    "All {label}": "Alle {label}",
    "Choose your": "Kies je",
    "Grand Total": "Totaalprijs",
    "Forgot password": "Wachtwoord vergeten",
    "Successfully created an account, you can log in with your new credentials": "Je account is succesvol aangemaakt, je kunt vanaf nu inloggen met je gegevens",
    "If an account is found with {email}, you will receive an email with a link to reset your password.": "Als er een account is gevonden met {email}, zul je een e-mail ontvangen met een link om je wachtwoord te resetten.",
    "Thank you for your order, {name}": "Bedankt voor je bestelling, {name}!",
    "You will soon receive an email with the details of your order. For questions about your order, visit our <a href=\"/contact\">customer service</a> and contact us!": "Je ontvangt spoedig een e-mail met de details van je bestelling. Voor vragen over je bestelling bezoek de <a href=\"/klantenservice\">klantenservice</a> en neem contact op!",
    "Your order number is: <strong>#{orderid}</strong>": "Je bestelnummer is: <strong>#{orderid}</strong>",
    "Best regards, {store}": "Met sportieve groet, {store}",
    "We expect to deliver on": "We verwachten te bezorgen op",
    "We will send a confirmation by e-mail to": "We sturen een bevestiging per e-mail naar",
    "Do you have any questions?": "Heb je nog vragen?",
    "Visit our <a href=\"/contact\"> customer service </a>": "Bezoek onze <a href=\"/klantenservice\">klantenservice</a>",
    "Prefix": "Aanhef",
    "Sir.": "Meneer",
    "Mrs.": "Mevrouw",
    "Birthday": "Geboortedatum",
    "I accept the Riverty {url} *": "Ik accepteer de Riverty {url} *",
    "terms of payment": "betalingsvoorwaarden",
    "Monday": "maandag",
    "Tuesday": "dinsdag",
    "Wednesday": "woensdag",
    "Thursday": "donderdag",
    "Friday": "vrijdag",
    "Saturday": "zaterdag",
    "Sunday": "zondag",
    "Closed": "Gesloten",
    "recommended": "Ons advies",
    "This is a secure connection": "Dit is een beveiligde verbinding",
    "tomorrow": "morgen",
    "Create an account for your next visit!": "Maak een account aan voor je volgende bezoek!",
    "The benefits of an account:": "De voordelen van een account:",
    "Order comments": "Order berichten",
    "contact_form.subject": "{name} heeft het contactformulier op {store}Name ingevuld.",
    "Search results": "Zoekresultaten",
    "Recommended": "Aanbevolen",
    "new": "Nieuw",
    "Pickup point": "Pickup point",
    "Something went wrong while restoring your password": "Er ging iets mis tijdens het herstellen van je wachtwoord",
    "Your password has been updated": "Uw wachtwoord is bijgewerkt",
    "Please enter a valid code": "Voer een geldige coupon code in",
    "The coupon code could not be cancelled, please try again later.": "De coupon code kon niet worden geannuleerd, probeer het later opnieuw.",
    "Choose your FREE product": "Kies jouw GRATIS product",
    "Please wait, your return request is being processed.": "Even geduld, je retour aanvraag wordt verwerkt.",
    "Apologies, something went wrong processing your return request.": "Excuses, er ging iets mis met het verwerken van je retour aanvraag.",
    "Please contact our customer service team for assistance at info@sportshop.com or on 0031416-652803.": "Neem contact op met onze klantenservice voor hulp via info@sportshop.com of op nummer 0416-652803.",
    "Status": "Status",
    "Used coupon": "Gebruikte kortingscode",
    "Click here to follow your order": "Klik hier om je bestelling te volgen",
    "My account": "Accountoverzicht",
    "Reset password": "Wachtwoord opnieuw instellen",
    "Cart": "Winkelwagen",
    "Checkout": "Afrekenen",
    "Thank you. We have received your complaint!": "Bedankt, je klacht is in goede orde ontvangen en zal door ons in behandeling worden genomen.",
    "Download invoice": "Download factuur",
    "Choose your color": "Kies je kleur",
    "Color": "Kleur",
    "Sorry we're down for maintenance": "Excuses, onze shop is even offline wegens onderhoud",
    "We'll be back shortly": "We hopen zo snel mogelijk weer online te zijn",
    "Your recommended size is currently not available!": "Jouw aanbevolen maat is momenteel niet beschikbaar!",
    "The cache of {cache} has been emptied": "De cache van {cache} is succesvol geleegd",
    "The cache could not be cleared": "De cache kon niet geleegd worden",
    "All fields must be filled": "Alle velden moeten gevuld zijn.",
    "To Kiyoh reviews page": "Geef je mening",
    "You already have an account with us. Please log in or continue without an account.": "Je hebt al een account bij ons. Log in of ga door zonder account.",
    "You can create an account after you\u2019ve purchased the order.": "Je kunt een account maken na het afrekenen.",
    "Did you mean {email}?": "Bedoelde je {email}?",
    "Delete account": "Verwijder account",
    "Yes, I want to permanently delete my account": "Ja, ik wil mijn account permanent verwijderen",
    "Enter your giftcard number and email address, a new giftcard will be send to this email.": "Voer je giftcardnummer en e-mailadres in, jouw nieuwe giftcard zal naar dit e-mailadres worden verzonden.",
    "Redeem giftcard": "Giftcard inwisselen",
    "Giftcard": "Giftcard",
    "Successfully requested new giftcard. Please check your email for the new giftcard code.": "Je nieuwe giftcard succesvol aangevraagd. Je ontvangt nieuwe giftcard per e-mail.",
    "Could not collect balance for given giftcard code. Please try again later or contact support.": "We kunnen het saldo van je giftcardcode niet ophalen. Probeer het later nog eens of neem contact op met onze klantenservice.",
    "Sold out": "Uitverkocht",
    "Choose your printing personalisation options": "Kies je bedrukking",
    "Personalise product with printing": "Bedrukken",
    "Unable to collect giftcard balance.": "We kunnen het saldo van je giftcardcode niet ophalen. Probeer het later nog eens of neem contact op met onze klantenservice.",
    "Giftcard has already been redeemed.": "Je ingegeven giftcardcode is reeds gebruikt.",
    "Giftcard has expired.": "Je ingegeven gitcardcode is verlopen.",
    "Giftcard has been blocked.": "Je ingegeven giftcardcode is geblokkeerd. Controleer of je de giftcard juist hebt ingevuld. Lukt dit alsnog niet? Stuur dan de code van de giftcard en het openstaande bedrag met de melding dat deze is geblokkeerd naar de klantenservice. Wij lossen dit voor je op.",
    "Add set to cart": "Voeg set toe aan winkelwagen",
    "{name} has been added to your cart.": "{name} is toegevoegd aan je winkelwagen.",
    "The coupon code isn't valid. Verify the code and try again.": "Couponcode niet geldig. Heb je een waardebon of giftcard? Klik dan giftcard aan bij betaalmethoden.",
    "Max {count} characters": "Maximaal {count} karakters",
    "Personalisation Title": "Product personaliseren",
    "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "De combinatie van het e-mailadres en het wachtwoord is niet bij ons bekend.",
    "Repeat email address": "Herhaal je e-mailadres",
    "E-mail addresses do not match.": "E-mailadressen komen niet overeen.",
    "Store": "Winkel",
    "Order for your team": "Bestel voor je team",
    "Add player | Add players": "Voeg speler toe | Voeg spelers toe",
    "Player": "Speler",
    "Check if all players meet the required options.": "Controleer of alle spelers aan de vereiste opties voldoen.",
    "Not all sizes are selected.": "Niet alle maten zijn geselecteerd.",
    "Swipe for more products": "Swipe voor meer producten",
    "Order this set for your entire team!": "Bestel deze set voor je hele team!",
    "{qty} pieces for {price} each": "{qty} stuks voor {price} per stuk",
    "Tierprices": "Staffelprijzen",
    " - {stock} in stock": " - {stock} op voorraad",
    "Choose your delivery time:": "Kies je bezorgdag:",
    "Loading totals summary...": "Een ogenblik geduld...",
    "Only {qty} available!": "Nog maar {qty} beschikbaar!",
    "Something went wrong, contact us for help.": "Er ging iets mis, neem contact op voor hulp.",
    "Show more": "Toon meer",
    "Other sports": "Andere sporten",
    "Billing- and/or shipping address is not valid": "Controleer uw adresgegevens",
    "Back": "Terug",
    "How does it work?": "Hoe werkt het?",
    "Remove product": "Verwijder product",
    "Add product": "Voeg product toe",
    "Desired delivery day": "Gewenste leverdag",
    "This product is in stock": "Dit product is op voorraad",
    "Available sizes": "Beschikbare maten",
    "Minimum length of this field must be equal or greater than {length} symbols. Leading and trailing spaces will be ignored.": "De minimumlengte van dit veld moet gelijk zijn aan of groter zijn dan {length} tekens. Voor- en achterliggende spaties worden genegeerd.",
    "Minimum of different classes of characters in password is {count}. Classes of characters: Lower Case, Upper Case, Digits, Special Characters.": "Het wachtwoord moet minstens {count} verschillende klassen van tekens bevatten. Klassen van tekens: Kleine letters, Hoofdletters, Cijfers, Speciale tekens.",
    "The first name is required.": "De voornaam is verplicht.",
    "The first name must be a valid string.": "De voornaam moet een geldige string zijn.",
    "The first name must be at least 2 characters long.": "De voornaam moet minstens 2 tekens lang zijn.",
    "The first name must not exceed 255 characters.": "De voornaam mag niet langer zijn dan 255 tekens.",
    "The last name is required.": "De achternaam is verplicht.",
    "The last name must be a valid string.": "De achternaam moet een geldige string zijn.",
    "The last name must be at least 2 characters long.": "De achternaam moet minstens 2 tekens lang zijn.",
    "The last name must not exceed 255 characters.": "De achternaam mag niet langer zijn dan 255 tekens.",
    "The email address is required.": "Het e-mailadres is verplicht.",
    "Please provide a valid email address.": "Geef een geldig e-mailadres op.",
    "The email address must not exceed 255 characters.": "Het e-mailadres mag niet langer zijn dan 255 tekens.",
    "The day of birth is required.": "De geboortedag is verplicht.",
    "The day of birth must be a number.": "De geboortedag moet een getal zijn.",
    "The day of birth must be at least 1.": "De geboortedag moet minstens 1 zijn.",
    "The month of birth is required.": "De geboortemaand is verplicht.",
    "The month of birth must be a number.": "De geboortemaand moet een getal zijn.",
    "The month of birth must be at least 1.": "De geboortemaand moet minstens 1 zijn.",
    "The year of birth is required.": "Het geboortejaar is verplicht.",
    "The year of birth must be a number.": "Het geboortejaar moet een getal zijn.",
    "The year of birth must be at least 4 digits long.": "Het geboortejaar moet minstens 4 cijfers lang zijn.",
    "The gender must be either Male or Female.": "Het geslacht moet mannelijk of vrouwelijk zijn.",
    "The password is required.": "Het wachtwoord is verplicht.",
    "The password confirmation does not match.": "De wachtwoordbevestiging komt niet overeen.",
    "As early as possible": "Zo snel mogelijk",
    "We expect to deliver your complete order on {date}": "We verwachten je volledige order te leveren op {date}",
    "The payment provider rejected the payment request, please use another payment method.": "De betaalprovider heeft het betalingsverzoek afgewezen, gebruik een andere betaalmethode.",
    "The payment is canceled, the order is not paid": "De betaling is geannuleerd, de bestelling is niet betaald",
    "Returns": "Retourneren",
    "Search your orders": "Zoek je bestelling",
    "Search": "Zoeken",
    "No order found with order id {orderId}": "Geen bestelling gevonden met ordernummer {orderId}",
    "Order placed on": "Bestelling geplaatst op",
    "An error occurred while fetching the order. Please try again later.": "Er is een fout opgetreden bij het ophalen van de order. Controleer je gegevens of probeer het later opnieuw.",
    "Choose return reason": "Kies retourreden",
    "Size": "Maat",
    "I want to return my products": "Ik wil mijn producten retourneren",
    "By initiating a return, you agree to our return policy.": "Door een retour aan te vragen, ga je akkoord met ons retourbeleid.",
    "Download return label": "Download retourlabel",
    "This product has custom personalisation options.": "Dit product heeft aangepaste personalisatie-opties.",
    "An error occurred while retrieving the order. Please check your details or try again later.": "Er is een fout opgetreden bij het ophalen van de bestelling. Controleer je gegevens of probeer het later opnieuw.",
    "Oops, something went wrong": "Oeps, er is iets misgegaan",
    "My returns": "Mijn retouren",
    "An error occurred during the payment process, please try again or use another payment method.": "Er is een fout opgetreden tijdens het betalingsproces, probeer het opnieuw of gebruik een andere betaalmethode.",
    "Why?": "Waarom?",
    "We recommend that you order <strong>one size larger</strong> than you normally wear.": "We raden u aan <strong>\u00e9\u00e9n maat groter</strong> te bestellen dan je normaal draagt.",
    "Do you want to merge your old and new shopping basket?": "Wil je jouw oude en nieuwe winkelmandje samenvoegen",
    "You already had items in your shopping basket. Would you like to merge them with the items you have now added?": "Je hebt nog producten in je winkelwagen liggen van je vorige bezoek aan onze shop. Wil je deze producten samenvoegen met je nieuwe order?",
    "No. Only continue with newly added items": "Nee. Alleen doorgaan met nieuw toegevoegde artikelen",
    "Yes. Merge and continue.": "Ja, winkelwagens samenvoegen",
    "Successfully saved address": "Adres opgeslagen",
    "I would like to:": "Ik wil graag:",
    "Track my order": "Mijn bestelling traceren",
    "Download my invoice": "Mijn factuur downloaden",
    "Return my products": "Mijn producten retourneren",
    "An error occurred while fetching the return label. Please try again later.": "Er is een fout opgetreden bij het ophalen van het retourlabel. Probeer het later opnieuw.",
    "Read our return policy": "Lees ons retourbeleid",
    "We still have these colors for size {size} in stock!": "We hebben deze kleur nog op voorraad voor maat {size}! | We hebben deze kleuren nog op voorraad voor maat {size}!",
    "Your last chosen payment method": "Jouw laatst gekozen betaal methode",
    "in {label}": "in {label}",
    "You can only compare up to {products} products at the same time.": "Je kunt maximaal {products} producten tegelijk vergelijken.",
    "Compare - {products}": "Vergelijk - {products}",
    "Compare products": "Vergelijk producten",
    "There are no specification found to compare.": "Er zijn geen specificaties gevonden om te vergelijken.",
    "Show differences": "Toon verschillen",
    "Compare {productCount} products": "Geen producten | Vergelijk 1 product | Vergelijk {productCount} producten",
    "Compare": "Vergelijk",
    "Your compare list is empty.": "Je vergelijk lijst is leeg.",
    "No products": "Geen producten",
    "You can compare up to {count} products": "Je kunt maximaal {count} producten vergelijken",
    "Go to cart": "Ga naar winkelmandje",
    "Remove from cart": "Verwijder uit winkelmandje"
}
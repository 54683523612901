const initialState = () => {
  return {
    pickupPoints: [],
    selectedPickupPoint: null,
    loadingPickupPoints: true,
    customer: {
      zipcode: null,
      lat: 0,
      long: 0,
    },
  };
}

const getters = {};

const mutations = {
  SET_LOADING_PICKUP_POINTS(state, status) {
    state.loadingPickupPoints = status;
  },
  SET_PICKUP_POINTS(state, pickupPoints) {
    state.pickupPoints = pickupPoints;
  },
  SET_CUSTOMER_DATA(state, customer) {
    state.customer = customer;
  },
  SET_PICKUP_POINT(state, pickupPoint) {
    if (pickupPoint === '') {
      state.selectedPickupPoint = null;
      return;
    }

    state.selectedPickupPoint = pickupPoint;
  },
};

const actions = {
  async collectPickupPoints({ rootState, commit }) {
    commit('SET_LOADING_PICKUP_POINTS', true);
    try {
      const { data } = await this.$solarClient.get(`checkout/postnl/pickup-points/nearest/${rootState['CheckoutCheckout']['shippingAddress']['postcode']}/${rootState['CheckoutCheckout']['shippingAddress']['country_id']}`);

      commit('SET_PICKUP_POINTS', data);
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false);
    }
  },
  async collectPickupPointsForZipcode({ commit }, payload) {
    commit('SET_LOADING_PICKUP_POINTS', true);
    try {
      const { data } = await this.$solarClient.get(`checkout/postnl/pickup-points/nearest/${payload.zipcode}/${payload.countryId}`);
      commit('SET_PICKUP_POINTS', data);
    } finally {
      commit('SET_LOADING_PICKUP_POINTS', false);
    }
  },
  setPickupPoint({ commit }, pickupPoint) {
    commit('SET_PICKUP_POINT', pickupPoint);
    commit('CheckoutCheckout/ADD_EXTENSION_ATTRIBUTE', {
      postnl_pickup_point: pickupPoint['LocationCode'],
    }, { root: true });
  },
  removePickupPoints({ commit }) {
    commit('SET_PICKUP_POINT', null);
    commit('CheckoutCheckout/REMOVE_EXTENSION_ATTRIBUTE', 'postnl_pickup_point', { root: true });
  },
  resetPickupPoints({ commit }) {
    commit('SET_PICKUP_POINTS', []);
    commit('SET_PICKUP_POINT', null);
    commit('SET_LOADING_PICKUP_POINTS', true);
  },

  async lookupAddress({ commit }, zipcode) {
    const { data } = await this.$solarClient.get(`/postcode/lookup/${zipcode}`);

    commit('SET_CUSTOMER_DATA', {
      zipcode: zipcode,
      lat: parseFloat(data['latitude']),
      lng: parseFloat(data['longitude']),
    });

    return true;
  }
}

const state = initialState();

const CheckoutPostNLPickupPoints = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

export default CheckoutPostNLPickupPoints;
